<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ name: 'nonHeritageDetail', query: { id: item.id, type: 2 } })">
            <span class="title">{{ item.name }}</span>
            <span class="detail">{{ item.remark }}</span>
        </div>
        <el-pagination @size-change="getPolicyList" @current-change="getPolicyList" :current-page.sync="page" :page-sizes="[10, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getPolicyList } from '@/api';
export default {
    name: 'NewsDetail',
    data() {
        return {
            tabs: 0,
            limit: 12,
            total: 0,
            page: 1,
            nav: [
                {
                    title: '首页',
                    path: '/',
                },
                {
                    title: '非遗文化',
                    to: { path: '/nonHeritage' },
                },
                {
                    title: '政策解读',
                },
            ],
        };
    },
    created() {
        this.getPolicyList();
    },

    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        getPolicyList() {
            let params = {
                pageSize: this.limit,
                pageNum: this.page,
            };
            getPolicyList(params).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
    },
};
</script>

<style scoped lang="less">
.el-breadcrumb {
    padding-bottom: 0;
}
.item {
    padding: 24px 0;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;

    .detail {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        align-self: stretch;
        margin-top: 12px;
        .ellipsisLine(1);
    }

    .title {
        font-weight: 500;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.8);
    }
}
</style>
